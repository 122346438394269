<template>
    <div class="landing-page-1-hp">
        <Header
            :products="products"
            :add-product="addProduct"
            :open-cart="openCart"
        />
        <AntivirusProtectionFeature />
        <CustomerTrust />
        <WhyMcAfee />
        <DeviceProtection />
        <Services />
        <DarkWebSection />

        <div class="mt-5 text-center">
            <h1>Ative sua proteção <span class="text-danger">hoje</span></h1>
        </div>

        <McafeeProductsCatalog
            :products="produtcEmphasis"
            :add-product="addProduct"
            :open-cart="openCart"
        />
        <div class="mt-5">
            <Faq />
        </div>
    </div>
</template>

<script setup>
    import Header from "./components/Header"
    import DarkWebSection from "@/components/DarkWebBanner"
    import AntivirusProtectionFeature from "@/modules/landingPages/mcafee/components/AntivirusProtectionFeature"
    import CustomerTrust from "@/modules/landingPages/mcafee/components/CustomerTrust"
    import WhyMcAfee from "@/components/WhyMcAfee"
    import DeviceProtection from "@/modules/landingPages/mcafee/components/DeviceProtection"
    import Services from "@/modules/landingPages/mcafee/components/Services"
    import Faq from "@/modules/landingPages/mcafee/components/Faq"

    import Configuration from "@/services/configuration";
    import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants";
    import Partners from "@/constants/partner/partner.constants";
    import McafeeProductsCatalog from "@/components/Sale/McafeeProductsCatalog";

    import { computed, onMounted, ref } from "vue";
    import { useStore } from "vuex";

    /*  */

    // * setup
    const store = useStore();

    // * props
    const products = computed(() => store.getters['saleFlow/product/getProducts']);
    const partner = ref('');
    const produtcEmphasis = computed(() => products.value.filter(item => item?.product?.offerInFocus === true));

    async function getProducts() {
        const productType = partner.value === Partners.MASTERCARD ? (
            productTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION_MASTERCARD_PARTNERSHIP
        ) : (
            productTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION
        );

        await store.dispatch('saleFlow/product/setAvailableProductType', [
            Configuration.productTypeOption[productType]
        ]);

        store.dispatch('saleFlow/getAllProductsCatalog');
    }

    function addProduct({ product, promotion }) {
        store.dispatch('cart/addProductCart', { product, promotion });
    }

    function openCart() {
        store.dispatch('cart/openCart');
    }

    onMounted(async () => {
        partner.value = store.getters.getPartner;
        store.dispatch('address/setNoClaroFields');
        await getProducts();
    });

</script>
